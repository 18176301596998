import { UniRoColumn } from "./Bit/UniRoColumn";
import { CustomAuthForm } from "./CustomAuth/CustomAuthForm";
import MpEndPoint from "./Mysql/MpEndPoint";
import UsersMenuItem from "./Users/UsersMenuItem";
import UsersMenuItemAuth from "./Users/UsersMenuItemAuth";

export const PluginsMap: any = {
    'bit-UniRoColumn': UniRoColumn,

    'custom.user.genToken': UsersMenuItem,
    'custom.user.genTokenAuth': UsersMenuItemAuth,
    
    'custom.routes': {
        '/c/custom-sign-up': CustomAuthForm,
        '/c/mp': MpEndPoint
    }
}